const info=[
    {
        logo : "saengthong.jpg",
        school: "Saengthong Vitthaya School",
        location: "Songkhla, Thailand",
        level: [
            "Primary School (Grade 1-6)",
            "Secondary School (Grade 7-9)",
        ],
    },
    {
        logo : "evakona.png",
        school: "Evakona Education",
        location: "Whitianga, New Zealand",
        level: ["English Language School (2 Months)"],
    },
    {
        logo : "papanui.png",
        school: "Papanui High School",
        location: "Chirstchurch, New Zealand",
        level: ["High School (Grade 11-12)"],
    },
    {
        logo : "rsu.png",
        school: "Rangsit University",
        location: "Pathum Thani, Thailand",
        level: ["University (Undergraduate)"],
    },

]

function getEdu(){
    return <div className="flex flex-col h-full justify-center gap-[15px] px-[30px]">
        {info.map(item=><div className="flex items-center gap-[50px]">
            <img src={item.logo} width={130} height={130} className="rounded-full brightness-75" alt={item.school+" logo"}/>
            <div className="flex flex-col gap-[10px]">
                <div className="flex flex-col">
                    <div className="font-bold text-[27.5px] leading-[34px]">{item.school}</div>
                    <div className="font-bold text-[14px] leading-[20px]">{item.location}</div>
                </div>
                <div className="flex flex-col">
                {item.level.map(lvl=><div className="font-bold text-[18px] leading-[28px]">{lvl}</div>)}
                </div>
            </div>
        </div>)}
    </div>
}

const Education=getEdu();
export default Education;