import MainCard from "./components/MainCard";
import Links from "./components/Links";
import ContactCard from "./components/ContactCard";

export default function App() {
  return <div className="flex gap-[100px] h-screen w-screen bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')] text-white font-serif p-[40px] items-center justify-center">
    <MainCard/>
    <ContactCard/>
    <Links/>
  </div>;
}
