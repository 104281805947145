const contacts={
        email: {
            title:"Email",
            items:[
                {
                    display: "contact @ toparin.me",
                    link: "mailto:contact@toparin.me"
                },
            ]
        },
        phone: {
            title:"Phone number",
            items:[
                {
                    display: "+66 97 202 2505",
                    link: "telto:+66972022505",
                },
            ]
        },
    }

function getContact(contact){
    return <div className="flex flex-col gap-[10px]">
        <div className="text-xl font-bold">{contact.title}</div>
        <div className="px-[10px]">
            {contact.items.map(item=><a href={item.link}>{item.display}</a>)}
        </div>
    </div>
}

export default function ContactCard(){
    return <div className="flex flex-col gap-[30px] bg-black/10 w-[300px] rounded-3xl p-[20px]">
        {getContact(contacts.email)}
        {getContact(contacts.phone)}
    </div>
}