import { HR } from "flowbite-react";

const about=[
    {
        title: "Nickname",
        item: "To",
    },
    {
        title: "Date of Birth",
        item: "1st of June, 2003",
    },
    {
        title: "Major",
        item: "Information And Communication Technology | Rangsit University",
    },
    {
        title: "Languages",
        item: "Thai (Native), English",
    },
]

function getAbout() {
    return about.map(obj=><div className="flex">
        <div className="w-[120px] font-bold">{obj.title}</div>
        <div className="w-[15px] font-bold">:</div>
        <div className="w-[250px]">{obj.item}</div>
    </div>)
}

function getProfile(){
    return <div className="flex flex-col py-[20px]">
        <div className="flex gap-[50px] justify-center">
            <img src="profile.jpg" width={175} className="rounded-full" alt="Parintorn"/>
            <div className="flex flex-col gap-[15px] justify-center">
                {getAbout()}
            </div>
        </div>
        <HR/>
        <div className="flex flex-col gap-[30px] px-[20px]">
            <div>
                <div>At the very beginning, I did not even know what to do in my life-</div>
                <div>so I decided to study financial engineering course.</div>
            </div>
            <div>
                <div>After 2 years there, I started to realize that I am very feeling focus on coding.</div>
                <div>I want to know everything in that programming language.</div>
                <div>At that time, I do not want to study financial field at all because I do not like it.</div>
            </div>
            <div>
                <div>At the end, I decided again to resign the course-</div>
                <div>and now I join the Information and Communication Technology for those reasons.</div>
            </div>
        </div>
    </div>
}

const Profile=getProfile();
export default Profile;