import { Tabs } from "flowbite-react";
import Skills from "./Skills";
import Resume from "./Resume";
import Profile from "./Profile";
import Education from "./Education";

const tabs=[
    {
        name: "Profile",
        items: Profile,
    },
    {
        name: "Resume",
        items: Resume,
    },
    // {
    //     name: "Projects",
    //     items: <></>
    // },
    {
        name: "Skills",
        items: Skills,
    },
    {
        name: "Education",
        items: Education,
    },
]



export default function MainCard(){

    const result=[];

    tabs.forEach((tab)=>{
        result.push(
        <Tabs.Item active title={tab.name}>
            <div className="flex flex-col w-full h-[600px] px-[20px]">
                {tab.items}
            </div>
        </Tabs.Item>)
    })

    return(
        <div className="bg-black/10 w-[750px] h-[700px] rounded-3xl">
            <Tabs variant="underline">
                {result}
            </Tabs>
        </div>
)}