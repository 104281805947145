
const mySkills = [
    {
        group: "Web Development",
        skill: [
            {
                name: "Languages",
                items: ["HTML", "JavaScript", "CSS"],
            },
            {
                name: "Frameworks",
                items: ["ReactJS", "Svelte"],
            },
        ]
    },
    {
        group: "Mobile Application Development",
        skill: [
            {
                name: "Languages",
                items: ["Dart"],
            },
            {
                name: "Frameworks",
                items: ["Flutter"],
            },
        ]
    },
    {
        group: "Programming Knowledge",
        skill: [
            {
                name: "Languages",
                items: ["Python", "Java", "C++", "C"],
            },
        ]
    },
    {
        group: "Query Knowledge",
        skill: [
            {
                name: "Languages",
                items: ["Python", "Java", "C++", "C"],
            },
            {
                name: "Concepts",
                items: ["Data Definition Language", "Data Manipulation Language"]
            }
        ]
    },
]

function getSkills() {
    return <div className="h-full justify-center flex flex-col gap-[60px]">{mySkills.map(item=>
        <div className="flex flex-col gap-[10px]">
            <div className="font-bold text-[18px] leading-[28px]">{item.group}</div>
            <div className="flex flex-col ml-[8px]">
                {item.skill.map(skill=>
                    <div className="flex gap-[6px]">
                        <div className="font-semibold">{skill.name}</div>
                        <div className="font-semibold">:</div>
                        <div>
                        {skill.items.map((obj, index)=>index!==item.skill.length-1?obj+", ":obj)}
                        </div>
                    </div>
                )}
            </div>
        </div>)}
    </div>}

const Skills = getSkills();
export default Skills;