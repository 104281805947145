import { Button } from "flowbite-react";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

const links = [
    {
        id: 'linkedin',
        name: 'LinkedIn',
        url: 'https://linkedin.com/in/parintorn-thammasitboon-274848290',
        icon: <FaLinkedinIn className="mr-2 h-5 w-5" color="white"/>,
    },
    {
        id: 'github',
        name: 'Github',
        url: 'https://github.com/parintorn0',
        icon: <FaGithub className="mr-2 h-5 w-5" color="white"/>,
    }
]

const btnTheme = {
    color: {
      linkedin: "bg-[#007AB6] hover:bg-[#007AB6]/90",
      github: "bg-[#24292F] hover:bg-[#24292F]/90"
    },
  };
  

export default function Links(){

    const result=[];
    links.forEach((link)=>{
        result.push(
        <Button theme={btnTheme} color={link.id} href={link.url} target="_blank">
        {link.icon}
        {link.name}
        </Button>
        )
    })
    
    return <div className="fixed right-[20px] bottom-[20px] flex flex-col gap-2">
        {result}
  </div>

}